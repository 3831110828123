// 首页
import {request} from "@/network/request.js";
import qs from 'qs';

export default{
  // 查询账户余额、库存总额等信息
  findAssetsReport(data){
    return request({
      method:'POST',
      url:'/report/index/findAssetsReport',
      data
    })
  },
  //查询客户分布信息
  findCustomerDistributionReport(data){
    return request({
      method:'POST',
      url:'/report/index/findCustomerDistributionReport',
      data
    })
  },
  //查询应账款信息
  findCustomerList(data){
    return request({
      method:'POST',
      url:'/report/index/findCustomerList',
      data
    })
  },
  // 查询仓库汇总信息
  findDepotFormReport(data){
    return request({
      method:'POST',
      url:'/report/index/findDepotFormReport',
      data
    })
  },
  // 查询-条件查询成交金额信息
  findIndexSalesMoneyReport(data){
    return request({
      method:'POST',
      url:'/report/index/findIndexSalesMoneyReport',
      data
    })
  },
  // 查询-条件查询报价数信息
  findOfferNumReport(data){
    return request({
      method:'POST',
      url:'/report/index/findOfferNumReport',
      data
    })
  },
  // 查询订单分布信息
  findOrderDistributionReport(data){
    return request({
      method:'POST',
      url:'/report/index/findOrderDistributionReport',
      data
    })
  },
  // 查询最新订单信息
  findOrderList(data){
    return request({
      method:'POST',
      url:'/report/index/findOrderList',
      data
    })
  },
  // 查询-条件查询订单量信息
  findOrderNumReport(data){
    return request({
      method:'POST',
      url:'/report/index/findOrderNumReport',
      data
    })
  },
  // 条件查询应付账款信息
  findSupplierList(data){
    return request({
      method:'POST',
      url:'/report/index/findSupplierList',
      data
    })
  },
  // 查询-条件查询新增客户数信息
  findIndexaddCustomerReport(data){
    return request({
      method:'POST',
      url:'/report/index/findIndexaddCustomerReport',
      data
    })
  },
  // 查询-销售业绩排行榜
  findSalesTop(data){
    return request({
      method:'POST',
      url:'/report/index/findSalesTop',
      data
    })
  },
  // 查询-销售业绩排行榜-业绩增幅统计表
  findSalesGrowth(data){
    return request({
      method:'POST',
      url:'/report/index/findSalesGrowth',
      data
    })
  },
  // 查询-订单数量排行榜
  findOrderNumTop(data){
    return request({
      method:'POST',
      url:'/report/index/findOrderNumTop',
      data
    })
  },
  // 查询-订单数量排行榜-订单增幅统计表
  findOrderNumGrowth(data){
    return request({
      method:'POST',
      url:'/report/index/findOrderNumGrowth',
      data
    })
  },
  // 查询-畅销产品top10
  findProductTop(data){
    return request({
      method:'POST',
      url:'/report/index/findProductTop',
      data
    })
  },
  // 查询-产品报价top10
  findProductOfferTop(data){
    return request({
      method:'POST',
      url:'/report/index/findProductOfferTop',
      data
    })
  },
}