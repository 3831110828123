<template>
  <div class="indexAll">
    <div class="content_left">
      <div class="card_row">
        <div class="card_col">
          <div class="card_top_row">
            <div class="card_top_title">
              <img src="../assets/img/views_index/icon_chengjiaojine.png"/>
              <span>成交金额</span>
            </div>
            <el-select class="card_select_box" v-model="bargainValue" placeholder="请选择" @change="handleBargainChange">
              <el-option
                v-for="item in monthOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <h2 class="statistic_content" :title="bargainData.salesMoney">￥ {{divisionMoney(bargainData.salesMoney)}}</h2>
          <div class="statistic_dec">同比增幅{{ bargainData.percentage}}%<i class="el-icon-question"></i></div>
        </div>
        <div class="card_col">
          <div class="card_top_row">
            <div class="card_top_title">
              <img src="../assets/img/views_index/icon_dingdanliang.png"/>
              <span>订单量</span>
            </div>
            <el-select class="card_select_box" v-model="orderValue" placeholder="请选择" @change="handleOrderChange">
              <el-option
                v-for="item in monthOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <h2 class="statistic_content">{{orderData.orderNum}}</h2>
          <div class="statistic_dec">复购率{{orderData.percentage}}%<i class="el-icon-question"></i></div>
        </div>
        <div class="card_col">
          <div class="card_top_row">
            <div class="card_top_title">
              <img src="../assets/img/views_index/icon_xinzengkehushu.png"/>
              <span>新增客户数</span>
            </div>
            <el-select class="card_select_box" v-model="serviceValue" placeholder="请选择" @change="handleServiceChange">
              <el-option
                v-for="item in monthOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <h2 class="statistic_content">{{serviceData.orderNum}}</h2>
          <div class="statistic_dec">转化率{{serviceData.percentage}}%<i class="el-icon-question"></i></div>
        </div>
        <div class="card_col">
          <div class="card_top_row">
            <div class="card_top_title">
              <img src="../assets/img/views_index/icon_baojiashu.png"/>
              <span>报价数</span>
            </div>
            <el-select class="card_select_box" v-model="offerValue" placeholder="请选择" @change="handleOfferChange">
              <el-option
                v-for="item in monthOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <h2 class="statistic_content">{{offerData.offerNum}}</h2>
          <div class="statistic_dec">成交率{{offerData.percentage}}%<i class="el-icon-question"></i></div>
        </div>
      </div>
      <div class="statistical_graph_box">
        <div class="tabs_header">
          <div class="tabs_list">
            <span class="tab_item" v-bind:class="{ active: (typeTag === 1) }" @click="clickTagsChange(1)">业绩增幅</span>
            <span class="tab_item" v-bind:class="{ active: (typeTag === 2) }" @click="clickTagsChange(2)">订单增幅</span>
            <span class="tab_item" v-bind:class="{ active: (typeTag === 3) }" @click="clickTagsChange(3)">畅销TOP10</span>
            <!-- <span class="tab_item" v-bind:class="{ active: (typeTag === 4) }" @click="clickTagsChange(4)">报价TOP10</span> -->
          </div>
          <div class="small_tabs_box">
            <div class="small_tabs_list">
              <span class="small_tab_item" v-bind:class="{ active: (DateTag === 0) }" @click="clickDateChange(0)">今日</span>
              <span class="small_tab_item" v-bind:class="{ active: (DateTag === 1) }" @click="clickDateChange(1)">本月</span>
              <span class="small_tab_item" v-bind:class="{ active: (DateTag === 2) }" @click="clickDateChange(2)">本季</span>
              <span class="small_tab_item" v-bind:class="{ active: (DateTag === 3) }" @click="clickDateChange(3)">本年</span>
            </div>
            <el-date-picker
              class="small_tabs_date"
              v-model="pickerValue"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="onDatePickerChange">
            </el-date-picker>
          </div>
        </div>
        <div class="tabs_content">
          <div class="conten_chart" id="statistics_chart">
          </div>
          <div class="conten_table">
            <div class="conten_table_title">
              {{typeTag === 1 ? "销售业绩排行榜" : typeTag === 2 ? "成交订单排行榜" : typeTag === 3 ? "销售产品TOP10" : "产品报价TOP10"}}
            </div>
            <el-table
              :data="tableData"
              size="mini"
              style="width: 100%">
              <el-table-column
                v-if="(typeTag === 1 || typeTag === 2)"
                show-overflow-tooltip
                prop="user_name"
                label="姓名">
              </el-table-column>
              <el-table-column
                v-if="(typeTag === 3 || typeTag === 4)"
                show-overflow-tooltip
                prop="name"
                label="名称">
              </el-table-column>
              <el-table-column
                v-if="(typeTag === 3 || typeTag === 4)"
                show-overflow-tooltip
                prop="productCode"
                label="编码">
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="billsCount"
                label="单数"
                width="60">
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="billsAmount"
                label="金额（元）"
                width="90">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="property_calculate_box">
        <div class="property_calculate_item">
          <div class="property_calculate_label">总资产</div>
          <div class="property_calculate_value">￥{{divisionMoney(assetsData.totalPrice)}}</div>
        </div>
        <div class="property_calculate_item">
          <div class="property_calculate_symbol">=</div>
        </div>
        <div class="property_calculate_item">
          <div class="property_calculate_label">账户余额</div>
          <div class="property_calculate_value">￥{{divisionMoney(assetsData.balance)}}</div>
        </div>
        <div class="property_calculate_item">
          <div class="property_calculate_symbol">+</div>
        </div>
        <div class="property_calculate_item">
          <div class="property_calculate_label">库存余额</div>
          <div class="property_calculate_value">￥{{divisionMoney(assetsData.stock)}}</div>
        </div>
        <div class="property_calculate_item">
          <div class="property_calculate_symbol">+</div>
        </div>
        <div class="property_calculate_item">
          <div class="property_calculate_label">应收付款</div>
          <div class="property_calculate_value">￥{{divisionMoney(assetsData.payment)}}</div>
        </div>
        <div class="property_calculate_item">
          <div class="property_calculate_symbol">-</div>
        </div>
        <div class="property_calculate_item">
          <div class="property_calculate_label">应欠付款</div>
          <div class="property_calculate_value">￥{{divisionMoney(assetsData.arrears)}}</div>
        </div>
      </div>
      <div class="statistical_graph_row">
        <div class="statistical_graph_col" id="client_chart"></div>
        <div class="statistical_graph_col" id="order_chart"></div>
        <div class="statistical_text_col">
          <div class="repertory_title">
            库存报告
          </div>
          <div class="repertory_row">
            <label class="repertory_col">成品数：</label><span>{{depotData.finishedProductNum}}种</span>
          </div>
          <div class="repertory_row">
            <label class="repertory_col">物料数：</label><span>{{depotData.materialProductNum}}种</span>
          </div>
          <div class="repertory_row">
            <label class="repertory_col">低库存：</label><span>{{depotData.warningNum}}种</span>
          </div>
          <div class="repertory_row">
            <label class="repertory_col">零库存：</label><span>{{depotData.zeroNum}}种</span>
          </div>
          <div class="repertory_row">
            <label class="repertory_col">超过一年库存：</label><span>{{depotData.timeoutNum}}种</span>
          </div>
          <div class="repertory_row">
            <label class="repertory_col">库存总金额约：</label><span>{{depotData.costAmount}}万</span>
          </div>
        </div>
      </div>
      <div class="statistical_table_row">
        <div class="statistical_table_col table_width55">
          <div class="statistical_table_header">
            <div class="statistical_table_title">最新订单</div>
            <router-link to="#" class="statistical_table_linke">详情</router-link>
          </div>
          <div class="statistical_table_sum"></div>
          <el-table
            :data="newestOrderData"
            size="small"
            style="width: 100%">
            <el-table-column
              show-overflow-tooltip
              prop="billsNo"
              label="订单号"
              class-name="order_column">
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="money"
              label="金额（元）"
              width="90">
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="billsDate"
              label="时间"
              width="90">
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="isSubmit"
              label="状态"
              width="90">
              <template slot-scope="scope">
                <span :style="{'color': setStatusColor(scope.row.isSubmit)}">{{setStatus(scope.row.isSubmit)}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="statistical_table_col table_flex">
          <div class="statistical_table_header">
            <div class="statistical_table_title">应收账款</div>
            <router-link to="#" class="statistical_table_linke">详情</router-link>
          </div>
          <div class="statistical_table_sum">
            应收总额：￥{{divisionMoney(receivableData.total)}}
          </div>
          <el-table
            :data="receivableData.list"
            size="small"
            style="width: 100%">
            <el-table-column
              show-overflow-tooltip
              prop="customerName"
              label="客户名">
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="waitGathering"
              label="欠款额（元）">
            </el-table-column>
          </el-table>
        </div>
        <div class="statistical_table_col table_flex">
          <div class="statistical_table_header">
            <div class="statistical_table_title">应付账款</div>
            <router-link to="#" class="statistical_table_linke">详情</router-link>
          </div>
          <div class="statistical_table_sum">应付总额：￥{{divisionMoney(copeWithData.total)}}</div>
          <el-table
            :data="copeWithData.list"
            size="small"
            style="width: 100%">
            <el-table-column
              show-overflow-tooltip
              prop="supplierName"
              label="客户名">
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="totalPayment"
              label="欠款额（元）">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="content_right">
      <div class="tasks_box">
        <h5 class="tasks_title">
          <img src="../assets/img/views_index/xse.png"/>
          <span>待办任务</span>
        </h5>
        <div class="tasks_text tasks_dot tasks_color1">待审批邮件<span class="tasks_num1">10</span>条</div>
        <div class="tasks_text tasks_dot tasks_color2">待审批报价单<span class="tasks_num2">10</span>条</div>
        <div class="tasks_text tasks_dot tasks_color3">待审批销售订单<span class="tasks_num3">10</span>条</div>
        <div class="tasks_text tasks_dot tasks_color4">待审批采购申请单<span class="tasks_num4">10</span>条</div>
        <div class="tasks_text tasks_dot tasks_color5">待审批采购订单<span class="tasks_num5">10</span>条</div>
        <div class="tasks_text tasks_dot tasks_color6">待审批出入库单<span class="tasks_num6">10</span>条</div>
        <div class="tasks_text tasks_dot tasks_color7">待审批质检单<span class="tasks_num7">10</span>条</div>
        <div class="tasks_text tasks_dot tasks_color8">待审批生产计划单<span class="tasks_num8">10</span>条</div>
      </div>
      <div class="wishes_box">
        <div class="wishes_title">
          <img src="../assets/img/views_index/xse.png"/>
          <span>团队寄语</span>
        </div>
        <p class="wishes_dec">感谢您为团队服务超过<span class="wishes_color">365</span>天</p>
        <p class="wishes_text">祝您生日快乐</p>
      </div>
      <div class="code_box">
        <div class="code_title">
          <img src="../assets/img/views_index/xse.png"/>
          <span>在线服务</span>
        </div>
        <div class="code_img">
          <img src="../assets/img/views_index/xse.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import homeApi from '@/network/home/index.js';

  export default{
    data() {
      return {
        monthOption:[{
          label: "今日",
          value: 0
        },{
          label: "本月",
          value: 1
        },{
          label: "本季",
          value: 2
        },{
          label: "本年",
          value: 3
        }],
        bargainValue: 1,
        orderValue: 1,
        serviceValue: 1,
        offerValue: 1,
        bargainData: "",
        orderData: "",
        serviceData: "",
        offerData: "",
        typeTag: 1,
        DateTag: 1,
        pickerValue: "",
        chartData: [],
        tableData: [],
        assetsData: "",
        depotData: "",
        newestOrderData: [],
        receivableData: [],
        copeWithData: []
      }
    },
    mounted() {
      this.handleBargainChange()
      this.handleOrderChange()
      this.handleServiceChange()
      this.handleOfferChange()
      this.getAssetsReport()
      this.getStatisticsList()
      this.getClientChart()
      this.getOrderChart()
      this.getDepotFormReport()
      this.getOrderList()
      this.getReceivableList()
      this.getCopeWithList()
    },
    methods: {
      // 获取账户余额、库存总额等信息
      getAssetsReport(){
        homeApi.findAssetsReport().then(res=>{
          if(res.code == 200){
            this.assetsData = res.data
          }
        })
      },
      // 成交金额查询
      handleBargainChange(){
        homeApi.findIndexSalesMoneyReport({
          dateType: this.bargainValue
        }).then(res=>{
          if(res.code == 200){
            let increase = res.data.salesMoney - res.data.salesBeforeMoney
            // if(increase < 0) increase = 0;
            this.bargainData = {...res.data, increase}
            console.log(this.bargainData)
          }
        })
      },
      // 订单量查询
      handleOrderChange(){
        homeApi.findOrderNumReport({
          dateType: this.orderValue
        }).then(res=>{
          if(res.code == 200){
            this.orderData = res.data
          }
        })
      },
      // 新增客服数查询
      handleServiceChange(){
        homeApi.findIndexaddCustomerReport({
          dateType: this.serviceValue
        }).then(res=>{
          if(res.code == 200){
            this.serviceData = res.data
          }
        })
      },
      // 报价数查询
      handleOfferChange(){
        homeApi.findOfferNumReport({
          dateType: this.offerValue
        }).then(res=>{
          if(res.code == 200){
            this.offerData = res.data
          }
        })
      },
      // 获取增幅或者Top10的数据
      clickTagsChange(t){
        this.typeTag = t;
        this.DateTag = 1
        this.pickerValue = ""
        this.getStatisticsList()
      },
      // 根据时间查询
      clickDateChange(t){
        this.DateTag = t
        this.pickerValue = ""
        this.getStatisticsList()
      },
      // 根据时间查询
      onDatePickerChange(){
        this.DateTag = ""
        this.getStatisticsList()
      },
      // 获取业绩增幅，订单增幅，畅销TOP10，报价TOP10数据
      getStatisticsList(){
        const [startTime, endTime] = this.pickerValue
        if(this.typeTag === 1){
          homeApi.findSalesTop({
            dateType: this.DateTag,
            startTime,
            endTime
          }).then(res=>{
            if(res.code == 200){
              this.tableData = res.data || []
            }
          })
          homeApi.findSalesGrowth({
            dateType: this.DateTag,
            startTime,
            endTime
          }).then(res=>{
            if(res.code == 200){
              this.chartData = res.data || []
              this.graphing()
            }
          })
        }else if(this.typeTag === 2){
          homeApi.findOrderNumTop({
            dateType: this.DateTag,
            startTime,
            endTime
          }).then(res=>{
            if(res.code == 200){
              this.tableData = res.data || []
            }
          })
          homeApi.findOrderNumGrowth({
            dateType: this.DateTag,
            startTime,
            endTime
          }).then(res=>{
            if(res.code == 200){
              this.chartData = res.data || []
              this.graphing()
            }
          })
        }else if(this.typeTag === 3){
          homeApi.findProductTop({
            dateType: this.DateTag,
            startTime,
            endTime
          }).then(res=>{
            if(res.code == 200){
              this.tableData = res.data || []
              this.chartData = res.data || []
              this.graphing()
            }
          })
        }else{
          homeApi.findProductOfferTop({
            dateType: this.DateTag,
            startTime,
            endTime
          }).then(res=>{
            if(res.code == 200){
              this.tableData = res.data
              this.chartData = res.data
              this.graphing()
            }
          })
        }
      },
      // 生成图表
      graphing(){
        let myChart = this.$echarts.init(document.getElementById('statistics_chart'))
        let option;
        if(this.typeTag === 1){
          option = this.performanceOption()
        }else if(this.typeTag === 2){
          option = this.orderOption()
        }else if(this.typeTag === 3){
          option = this.marketOption()
        }else{
          option = this.priceOption()
        }
        myChart.setOption(option);
      },
      // 获取x轴或者y轴数组
      getXName(x){
        let axisArr = []
        if(this.typeTag === 1){
          if(x){
            this.chartData.map(item=>{
              axisArr.push(item.date)
            })
          }else{
            this.chartData.map(item=>{
              axisArr.push(item.billsAmount)
            })
          }
        }else if(this.typeTag === 2){
          if(x){
            this.chartData.map(item=>{
              axisArr.push(item.date)
            })
          }else{
            this.chartData.map(item=>{
              axisArr.push(item.billsCount)
            })
          }
        }else if(this.typeTag === 3){
          if(x){
            this.chartData.map(item=>{
              axisArr.push(item.name)
            })
          }else{
            this.chartData.map(item=>{
              axisArr.push(item.billsAmount)
            })
          }
        }else{
          if(x){
            this.chartData.map(item=>{
              axisArr.push(item.name)
            })
          }else{
            this.chartData.map(item=>{
              axisArr.push(item.billsCount)
            })
          }
        }
        return axisArr
      },
      // 业绩图表option
      performanceOption(){
        let xAxisData = this.getXName(1)
        let yAxisData = this.getXName()
        return {
          title: {
              text: "销售业绩排行榜",
              textStyle:{
                color: '#000000',
                fontWeight: 'normal',
                fontSize: 14
              }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: 0,
            right: 80,
            bottom: 0,
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                rotate: 70,
                color: "#000000"
              },
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          color: ['#0091ff'],
          series: [
            {
              name: '成交金额',
              type: 'line',
              data: yAxisData,
              animation:true,
              smooth: true
            }
          ]
        }
      },
      // 订单图表option
      orderOption(){
        let xAxisData = this.getXName(1)
        let yAxisData = this.getXName()
        return {
          title: {
              text: "成交订单排行榜",
              textStyle:{
                color: '#000000',
                fontWeight: 'normal',
                fontSize: 14
              }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: 0,
            right: 80,
            bottom: 0,
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                rotate: 70,
                color: "#000000"
              },
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          color: ['#0091ff'],
          series: [
            {
              name: '订单数',
              type: 'line',
              data: yAxisData,
              animation:true,
              smooth: true
            }
          ]
        }
      },
      // 销售图表option
      marketOption(){
        let xAxisData = this.getXName(1)
        let yAxisData = this.getXName()
        return {
          title: {
              text: "销售产品TOP10",
              textStyle:{
                color: '#000000',
                fontWeight: 'normal',
                fontSize: 14
              }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: 0,
            right: 80,
            bottom: 0,
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                rotate: 70,
                color: "#000000"
              },
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          color: ['#0091ff'],
          series: [
            {
              name: '销售金额',
              type: 'bar',
              data: yAxisData,
              animation:true,
            }
          ]
        }
      },
      // 报价图表option
      priceOption(){
        let xAxisData = this.getXName(1)
        let yAxisData = this.getXName()
        return {
          title: {
              text:"产品报价TOP10",
              textStyle:{
                color: '#000000',
                fontWeight: 'normal',
                fontSize: 14
              }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: 0,
            right: 80,
            bottom: 0,
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                rotate: 70,
                color: "#000000"
              },
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          color: ['#0091ff'],
          series: [
            {
              name: '报价单数量',
              type: 'bar',
              data: yAxisData,
              animation:true,
            }
          ]
        }
      },
      // 金额展示添加逗号处理
      divisionMoney(val){
        if(val){
          let str = (val + '').split('.')
          let intSum = str[0].replace(/\B(?=(?:\d{3})+$)/g, ',')
          let dot = str[1] ? '.' + str[1] : ''
          let num = intSum + dot
          return num
        }else{
          return 0
        }
      },
      // 客户分布统计图表
      getClientChart(){
        homeApi.findCustomerDistributionReport().then(res=>{
          if(res.code == 200){
            let seriesData = res.data.map(item=>({
              name:item.country,
              value: item.num
            }))
            let myChart = this.$echarts.init(document.getElementById('client_chart'))
            let option = {
              tooltip: {
                trigger: 'item'
              },
              title: {
                  text:"客户分布",
                  textStyle:{
                    color: '#000000',
                    fontWeight: 'normal',
                    fontSize: 16
                  },
                  top: 20,
                  left: 20,
              },
              color: [
                '#5470c6',
                '#91cc75',
                '#fac858',
                '#ee6666',
                '#73c0de',
                '#3ba272',
                '#fc8452',
                '#9a60b4',
                '#ea7ccc'
              ],
              series: [
                {
                  name: '客户分布',
                  type: 'pie',
                  radius: [30, 150],
                  center: ['50%', '50%'],
                  roseType: 'area',
                  data: seriesData,
                }
              ]
            };
            myChart.setOption(option);
          }
        })
      },
      // 订单分布统计图表
      getOrderChart(){
        homeApi.findOrderDistributionReport().then(res=>{
          if(res.code == 200){
            let seriesData = res.data.map(item=>({
              name:item.name+"-"+item.productCode,
              value: item.billsCount
            }))
            let myChart = this.$echarts.init(document.getElementById('order_chart'))
            let option = {
              tooltip: {
                trigger: 'item'
              },
              title: {
                  text:"订单分布",
                  textStyle:{
                    color: '#000000',
                    fontWeight: 'normal',
                    fontSize: 16
                  },
                  top: 20,
                  left: 20,
              },
              color: [
                '#5470c6',
                '#91cc75',
                '#fac858',
                '#ee6666',
                '#73c0de',
                '#3ba272',
                '#fc8452',
                '#9a60b4',
                '#ea7ccc'
              ],
              series: [
                {
                  name: '订单分布',
                  type: 'pie',
                  radius: [30, 150],
                  center: ['50%', '50%'],
                  roseType: 'area',
                  data: seriesData,
                }
              ]
            };
            myChart.setOption(option);
          }
        })
      },
      // 获取库存报告
      getDepotFormReport(){
        homeApi.findDepotFormReport().then(res=>{
          if(res.code == 200){
            this.depotData = res.data
          }
        })
      },
      // 获取最新订单
      getOrderList(){
        homeApi.findOrderList().then(res=>{
          if(res.code == 200){
            this.newestOrderData = res.data
          }
        })
      },
      // 订单状态颜色
      setStatusColor(status){
        switch(status){
          case 0:
            return '#5470c6'
          case 1:
            return '#91cc75'
          case 2:
            return '#fac858'
          case 3:
            return '#ee6666'
          case 4:
            return '#73c0de'
          case 5:
            return '#3ba272'
          default:
            return '#fc8452'
        }
      },
      // 订单状态转换
      setStatus(status){
        switch(status){
          case 0:
            return "未提交"
          case 1:
            return "审核中"
          case 2:
            return "已驳回"
          case 3:
            return "复审中"
          case 4:
            return "已审核"
          case 5:
            return "处理中"
          default:
            return "已完成"
        }
      },
      // 获取应收账款
      getReceivableList(){
        homeApi.findCustomerList().then(res=>{
          if(res.code == 200){
            this.receivableData = res.data
          }
        })
      },
      // 获取应付账款
      getCopeWithList(){
        homeApi.findSupplierList().then(res=>{
          if(res.code == 200){
            this.copeWithData = res.data
          }
        })
      }
    }
  }
</script>

<style lang="less">
  .indexAll{
    padding: 20px 10px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    background-color: #f3f3f4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    .content_left{
      margin-right: 10px;
      flex: 1;
      .card_row{
        display: flex;
        justify-content: space-between;
        .card_col{
          flex: 1;
          padding: 20px;
          margin-right: 10px;
          background: #ffffff;
          border-radius: 8px;
          .card_top_row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            .card_top_title{
              display: flex;
              align-items: center;
              font-size: 18px;
              img{
                margin-right: 8px;
                width: 36px;
              }
            }
            .card_select_box{
              width: 90px;
              height: 32px;
              input{
                line-height: 32px;
                height: 32px;
              }
            }
          }
          .statistic_dec{
            margin-bottom: 10px;
          }
          &:last-child{
            margin-right: 0;
          }
        }
      }
      .statistical_graph_box{
        margin-top: 10px;
        padding: 10px 0 10px 30px;
        background: #ffffff;
        border-radius: 8px;
        .tabs_header{
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          border-bottom: 1px solid #eee;
          .tabs_list{
            display: flex;
            font-size: 18px;
            .tab_item{
              padding: 10px 0;
              margin-right: 15px;
              position: relative;
              cursor: pointer;
              &.active{
                color: #0091ff;
                &::after{
                  content: "";
                  position: absolute;
                  left: 0;
                  bottom: -1px;
                  width: 100%;
                  height: 1px;
                  background-color: #0091ff;
                }
              }
            }
          }
          .small_tabs_box{
            display: flex;
            margin-bottom: 8px;
            .small_tabs_list{
              display: flex;
              // align-items: center;
              .small_tab_item{
                padding: 8px 0;
                margin-right: 30px;
                cursor: pointer;
                &.active{
                  color: #0091ff;
                }
              }
            }
            .small_tabs_date{
              margin-right: 15px;
              height: 32px;
              .el-icon-date{
                line-height: 24px;
              }
              .el-range-separator{
                line-height: 24px;
              }
            }
          }
        }
        .tabs_content{
          display: flex;
          padding: 30px 20px 30px 15px;
          .conten_chart{
            flex: 1;
            position: relative;
            height: 437px;
          }
          .conten_table{
            width: 25%;
            .conten_table_title{
              margin-bottom: 20px;
              font-size: 16px;
            }
          }
        }
      }
      .property_calculate_box{
        padding: 65px 60px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 10px;
        background-color: #ffffff;
        border-radius: 8px;
        .property_calculate_item{
          text-align: center;
          .property_calculate_label{
            font-size: 16px;
          }
          .property_calculate_value{
            margin-top: 5px;
            font-size: 28px;
            font-weight: bold;
          }
          .property_calculate_symbol{
            font-size: 16px;
          }
        }
      }
      .statistical_graph_row{
        display: flex;
        margin-top: 10px;
        .statistical_graph_col{
          margin-right: 10px;
          width: 550px;
          height: 400px;
          background-color: #ffffff;
          border-radius: 8px;
        }
        .statistical_text_col{
          padding: 20px;
          flex: 1;
          background-color: #ffffff;
          border-radius: 8px;
          font-size: 16px;
          .repertory_title{
            margin-bottom: 50px;
          }
          .repertory_row{
            display: flex;
            justify-content: space-between;
            padding: 10px 40px;
          }
        }
      }
      .statistical_table_row{
        display: flex;
        margin-top: 10px;
        .statistical_table_col{
          margin-right: 10px;
          padding: 20px;
          background-color: #ffffff;
          border-radius: 8px;
          &:last-child{
            margin-right: 0;
          }
          &.table_width55{
            width: 550px;
          }
          &.table_flex{
            flex: 1;
          }
          .statistical_table_header{
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 34px;
            .statistical_table_linke{
              position: relative;
              padding-right: 15px;
              color: #999999;
              text-decoration: none;
              &::after{
                content: '>';
                font-size: 18px;
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }
          .statistical_table_sum{
            margin-top: 5px;
            line-height: 30px;
            height: 30px;
            font-size: 14px;
          }
          .el-table__body-wrapper{
            .order_column{
              color: #0091ff;
            }
          }
        }
      }
    }
    .content_right{
      width: 305px;
      .tasks_box{
        padding: 15px;
        background: #ffffff;
        border-radius: 8px;
        .tasks_title{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          font-size: 16px;
          img{
            margin-right: 8px;
            width: 36px;
          }
        }
        .tasks_text{
          position: relative;
          padding-left: 45px;
          font-size: 16px;
          color: #999999;
          line-height: 32px;
          &.tasks_dot{
            &::after{
              position: absolute;
              top: 12px;
              left: 20px;
              display: block;
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 50%;
            }
            &.tasks_color1::after{
              background-color: #5470c6;
            }
            &.tasks_color2::after{
              background-color: #91cc75;
            }
            &.tasks_color3::after{
              background-color: #fac858;
            }
            &.tasks_color4::after{
              background-color: #ee6666;
            }
            &.tasks_color5::after{
              background-color: #73c0de;
            }
            &.tasks_color6::after{
              background-color: #3ba272;
            }
            &.tasks_color7::after{
              background-color: #fc8452;
            }
            &.tasks_color8::after{
              background-color: #9a60b4;
            }
          }
          .tasks_num1{
            color: #5470c6;
          }
          .tasks_num2{
            color: #91cc75;
          }
          .tasks_num3{
            color: #fac858;
          }
          .tasks_num4{
            color: #ee6666;
          }
          .tasks_num5{
            color: #73c0de;
          }
          .tasks_num6{
            color: #3ba272;
          }
          .tasks_num7{
            color: #fc8452;
          }
          .tasks_num7{
            color: #9a60b4;
          }
        }
      }
      .wishes_box{
        margin-top: 10px;
        padding: 15px;
        background: #ffffff;
        border-radius: 8px;
        .wishes_title{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          font-size: 16px;
          img{
            margin-right: 8px;
            width: 36px;
          }
        }
        .wishes_dec{
          text-align: center;
          .wishes_color{
            color: #0091ff;
          }
        }
        .wishes_text{
          margin-top: 20px;
          text-align: right;
        }
      }
      .code_box{
        margin-top: 10px;
        padding: 15px;
        background: #ffffff;
        border-radius: 8px;
        .code_title{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          font-size: 16px;
          img{
            margin-right: 8px;
            width: 36px;
          }
        }
        .code_img{
          display: flex;
          justify-content: center;
          padding: 20px 0;
          img{
            width: 185px;
          }
        }
      }
    }
    .statistic_content{
      font-size: 36px;
      text-align: center;
      white-space: nowrap;
      text-overflow:ellipsis;
      overflow: hidden;
      width: 292px;
    }
    .statistic_dec{
      color: #999999;
      text-align: center;
    }
  }
</style>
